import { documentToReactComponents, Options } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import React, { Component } from "react";
import {
  HeadingFive,
  HeadingFour,
  HeadingOne,
  HeadingSix,
  HeadingThree,
  HeadingTwo,
} from "../Shared/RichtextUi/Headings";
import { ParagraphWide } from "../Shared/RichtextUi/ParagraphWide";

interface FooterContentProps {
  contentfulFooterContentForPages: any;
}

export class FooterContent extends Component<FooterContentProps> {
  private provideOptions(): Options {
    const options: Options = {
      renderNode: {
        [BLOCKS.DOCUMENT]: (node, children) => (
          <div className="container grid-demo grid-demo-underlined  footer-content">{children}</div>
        ),
        [BLOCKS.PARAGRAPH]: (_, children) => <ParagraphWide paragraph={children} />,
        [BLOCKS.HEADING_1]: (_, children) => <HeadingOne heading={children} />,
        [BLOCKS.HEADING_2]: (_, children) => <HeadingTwo heading={children} />,
        [BLOCKS.HEADING_3]: (_, children) => <HeadingThree heading={children} />,
        [BLOCKS.HEADING_4]: (_, children) => <HeadingFour heading={children} />,
        [BLOCKS.HEADING_5]: (_, children) => <HeadingFive heading={children} />,
        [BLOCKS.HEADING_6]: (_, children) => <HeadingSix heading={children} />,
      },
    };
    return options;
  }

  render(): JSX.Element {
    const {
      content: { raw },
    } = this.props.contentfulFooterContentForPages;

    return <div>{documentToReactComponents(JSON.parse(raw), this.provideOptions())}</div>;
  }
}
