import React, { Fragment, useEffect, useRef, useState } from "react";
import { ScrollToTop } from "../Helpers/ScrollToTop";
import Loader from "../Shared/Loader";
import Footer from "./Footer";
import Header from "./Header";

interface LayoutProps {
  children: React.ReactNode;
  contentfulFooterContentForPages?: any;
}

export default function Layout({
  children,
  contentfulFooterContentForPages,
}: LayoutProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const chatSubBtn = useRef<HTMLButtonElement>(null);

  const [showLoader, setShowLoader] = useState<boolean>(true);

  const [showScroll, setShowScroll] = useState<boolean>(false);

  useEffect(() => {
    let isCancelled = false;
    if (typeof document === "undefined" || typeof window === "undefined") {
      return null;
    }
    if (!isCancelled) {
      (function (w: any, d) {
        if (d.getElementById("chat-bot-launcher-container")) {
          return;
        }
        w.CollectId = "5e69c63e1e420270b166f293";
        const h = d.head || d.getElementsByTagName("head")[0];
        const s = d.createElement("script");
        s.setAttribute("type", "text/javascript");
        s.setAttribute("src", "https://collectcdn.com/launcher.js");
        s.onload = function (ev) {
          const collectchat = w.collectchat || {};

          collectchat.on("complete", () => {
            chatSubBtn.current?.click();
          });
        };
        h.appendChild(s);
      })(window, document);

      (function (window: any) {
        window.__insp = window.__insp || [];
        window.__insp.push(["wid", 756725622]);
        const ldinsp = function () {
          if (typeof window.__inspld != "undefined") return;
          window.__inspld = 1;
          const insp = document.createElement("script");
          insp.type = "text/javascript";
          insp.async = true;

          insp.id = "inspsync";
          insp.src =
            (document.location.protocol === "https:" ? "https" : "http") +
            "://cdn.inspectlet.com/inspectlet.js?wid=756725622&r=" +
            Math.floor(new Date().getTime() / 3600000);
          const x = document.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(insp, x);
        };
        ldinsp();
      })(window);
    }

    const tId = setTimeout(() => {
      if (!isCancelled) {
        setShowLoader(false);
      }
    }, 100);

    const observer: IntersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShowScroll(false);
          } else {
            setShowScroll(true);
          }
        });
      },
      { threshold: 1 },
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
      isCancelled = true;
      clearTimeout(tId);
    };
  }, []);

  return (
    <Fragment>
      <Loader show={showLoader} />

      <Header />
      <div className="scroller-ref" ref={ref} />
      <main>{children}</main>
      <Footer contentfulFooterContentForPages={contentfulFooterContentForPages || null} />
      <ScrollToTop showScroll={showScroll} />
      <button id="chat-bot-ref" style={{ display: "none" }} ref={chatSubBtn}></button>
    </Fragment>
  );
}
