import React, { FC, ReactNode } from "react";

export const HeadingOne: FC<{ heading: ReactNode }> = ({ heading }) => {
  return (
    <div className="text-dark col-xl-9 pt-2 grid-headings pb-1">
      <h1 className="unit-body">{heading}</h1>
    </div>
  );
};

export const HeadingTwo: FC<{ heading: ReactNode }> = ({ heading }) => {
  return (
    <div className="text-dark col-xl-9 pt-2 grid-headings pb-1">
      <h2 className="unit-body">{heading}</h2>
    </div>
  );
};

export const HeadingThree: FC<{ heading: ReactNode }> = ({ heading }) => {
  return (
    <div className="text-dark col-xl-9 pt-2 grid-headings pb-1">
      <h3 className="unit-body">{heading}</h3>
    </div>
  );
};

export const HeadingFour: FC<{ heading: ReactNode }> = ({ heading }) => {
  return (
    <div className="text-dark col-xl-9 pt-2 grid-headings pb-1">
      <h4 className="unit-body">{heading}</h4>
    </div>
  );
};

export const HeadingFive: FC<{ heading: ReactNode }> = ({ heading }) => {
  return (
    <div className="text-dark col-xl-9 pt-2 grid-headings pb-1">
      <h5 className="unit-body">{heading}</h5>
    </div>
  );
};

export const HeadingSix: FC<{ heading: ReactNode }> = ({ heading }) => {
  return (
    <div className="text-dark col-xl-9 pt-2 grid-headings pb-1">
      <h6 className="unit-body">{heading}</h6>
    </div>
  );
};
