import { FooterContent } from "@/components/Helpers/FooterContent";
import { goTo } from "@/utils/redirect";
import { Link } from "gatsby";
import React from "react";

interface FooterProps {
  contentfulFooterContentForPages?: any;
}

export default class Footer extends React.Component<FooterProps> {
  render(): JSX.Element {
    return (
      <section className="section section-md-last block-footer-classic m-0 mt-4">
        {this.props.contentfulFooterContentForPages && (
          <FooterContent
            contentfulFooterContentForPages={this.props.contentfulFooterContentForPages}
          />
        )}
        <div className="block-footer-classic-wrap">
          <div className="container">
            <footer className="section footer-classic footer-classic_boxed text-white-50 text">
              <div className="hr container">
                <hr />
              </div>
              <div className="footer-classic-main">
                <div className="container">
                  <div className="row row-50 justify-content-lg-between">
                    <div className="col-sm-7 col-lg-3 col-xl-2">
                      <h4 className="footer-classic-title text-white">Zartek</h4>
                      <p className="col-12 footer-text p-0">
                        <span className="text ">
                          Zartek Technologies is a leading mobile app development company in Qatar,
                          providing a comprehensive range of services including web app development,
                          UI design, Branding, and more.
                        </span>
                      </p>
                    </div>
                    <div className="col-sm-5 col-lg-3 col-xl-2">
                      <h4 className="footer-classic-title text-white">Helpful Links</h4>
                      <ul className="list footer-classic-list text-white">
                        <li>
                          <Link to="/about-us/">About Us</Link>
                        </li>
                        <li>
                          <Link to="/services/">Services</Link>
                        </li>
                        <li>
                          <Link to="/our-work/">Portfolio</Link>
                        </li>
                        <li>
                          <a
                            href="https://tyiilhexsjg.typeform.com/to/LiVxOGBv"
                            target="_"
                            rel="noreferrer"
                          >
                            Partner with us
                          </a>
                        </li>
                        <li>
                          <Link to="/careers/">Careers</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-7 col-lg-5 col-xl-3">
                      <h4 className="footer-classic-title text-white">Locations</h4>
                      <ul className="list footer-classic-list text-white">
                        <li>
                          <Link to="/">Doha, Qatar</Link>
                        </li>
                        <li>
                          <a href="https://zartek.in" target="_blank" rel="noreferrer">
                            Kerala, India
                          </a>
                        </li>
                        <li>
                          <a href="https://zartek.ca">Ontario, Canada</a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-5 col-lg-9 col-xl-2">
                      <h4 className="footer-classic-title text-white">Contact Us</h4>
                      <div className="row row-20 row-sm-35">
                        <div className="col-6 col-sm-12 col-lg-8 col-xl-12">
                          <div className="row row-10 text-white-50">
                            <div className="col-lg-6 col-xl-12">
                              <a href="mailto:info@zartek.qa" id="footer-email-ref">
                                info@zartek.qa
                              </a>
                            </div>
                            <div className="col-lg-6 col-xl-12">
                              <a href="tel:+91 8069647545" id="footer-email-ref">
                                +91 8069647545
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-sm-12 col-lg-4 col-xl-12 text-right text-sm-left">
                          <div className="group group-xs">
                            <i
                              className="link link-social-1 mdi mdi-twitter"
                              onClick={() => goTo("https://twitter.com/zartekq")}
                              aria-label="tw"
                            />

                            <i
                              onClick={() => goTo("https://www.facebook.com/ZartekQ/")}
                              className="link link-social-1 mdi mdi-facebook"
                              aria-label="fb"
                            />

                            <i
                              className="link link-social-1 mdi mdi-instagram"
                              onClick={() => goTo("https://www.instagram.com/zartek.q")}
                              aria-label="insta"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hr container">
                <hr />
              </div>
              <div className="footer-classic-aside">
                <div className="container">
                  <p className="text-white-50 rights">
                    <span>©&nbsp; </span>
                    <span className="copyright-year">2020</span>
                    <span>&nbsp;</span>
                    <span>Zartek</span>
                    <span>.&nbsp;</span>
                    <span> All Right Reserved</span>
                  </p>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </section>
    );
  }
}
