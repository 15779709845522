import React, { FC } from "react";

export const ScrollToTop: FC<{ showScroll: boolean }> = ({ showScroll }) => {
  if (!showScroll) {
    return null;
  }

  function handleScrollToTop(): void {
    if (typeof window !== "undefined") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }

  return (
    <div
      onClick={handleScrollToTop}
      id="ui-to-top"
      role="button"
      tabIndex={0}
      className="ui-to-top mdi mdi-chevron-up active"
    />
  );
};
