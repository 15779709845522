import useHandleClose from "@/utils/clickedOutsideDetector";
import { Link } from "gatsby";
import React, { Fragment, useEffect, useRef, useState } from "react";
import BrandLogo from "./BrandLogo";
import SocialMediaLinks from "./SocialMediaLinks";

interface HeaderProps {
  children?: React.ReactNode;
}

export default function Header({}: HeaderProps): JSX.Element {
  const [switchToBurger, setSwitchToBurger] = useState<boolean>(true);
  const [isBurgerActive, setIsBurgerActive] = useState<boolean>(false);
  const [isUnmounted, setIsUnmounted] = useState<boolean>(false);

  const menuRef = useRef<HTMLDivElement>(null);

  useHandleClose(menuRef, () => {
    if (isBurgerActive && !isUnmounted) {
      setIsBurgerActive(false);
    }
  });

  useEffect(() => {
    let isCancelled = false;

    if (typeof window !== "undefined") {
      if (isCancelled) {
        return;
      }
      if (window.innerWidth < 1076) {
        setSwitchToBurger(true);
      } else {
        setSwitchToBurger(false);
        setIsBurgerActive(false);
      }
      window.addEventListener("resize", () => {
        if (window.innerWidth < 1076) {
          setSwitchToBurger(true);
        } else {
          setSwitchToBurger(false);
          setIsBurgerActive(false);
        }
      });
    }

    return () => {
      isCancelled = true;
      setIsUnmounted(true);
    };
  }, []);

  function handeBurgerToggle() {
    setIsBurgerActive(!isBurgerActive);
  }

  return (
    <Fragment>
      <header className="page animated">
        <section className="section page-header-3 header-section header-offset">
          <div className={`rd-navbar-wrap  ${switchToBurger && "rd-navbar-fixed"}`}>
            <nav
              className={`rd-navbar rd-navbar-classic rd-navbar-original rd-navbar-static rd-navbar--is-stuck ${
                switchToBurger && "rd-navbar-fixed"
              } `}
            >
              <div className="rd-navbar-main m-0">
                <div className="rd-navbar-panel">
                  <button
                    onClick={handeBurgerToggle}
                    className={`rd-navbar-toggle toggle-original ${isBurgerActive && "active"}`}
                  >
                    <span />
                  </button>

                  <BrandLogo />
                </div>
                <div
                  className={`rd-navbar-nav-wrap toggle-original-elements  ${
                    isBurgerActive && "active burger-open header-margin"
                  }`}
                  ref={menuRef}
                >
                  <ul
                    className={`rd-navbar-nav ${
                      isBurgerActive && "active burger-open-nav pl-3 pr-3"
                    }`}
                  >
                    {navbarLinks.map(({ linkTitle, redirectTo }, index) => (
                      <li className="rd-nav-item" key={index}>
                        <Link className="rd-nav-link" to={redirectTo} activeClassName="active">
                          {linkTitle}
                        </Link>
                      </li>
                    ))}
                  </ul>
                  {!switchToBurger && <SocialMediaLinks />}
                </div>
              </div>
            </nav>
          </div>
        </section>
      </header>
    </Fragment>
  );
}

const navbarLinks = [
  {
    linkTitle: "Home",
    redirectTo: "/",
  },
  {
    linkTitle: "About us",
    redirectTo: "/about-us/",
  },
  {
    linkTitle: "Our work",
    redirectTo: "/our-work/",
  },
  {
    linkTitle: "Branding",
    redirectTo: "/branding/",
  },
  {
    linkTitle: "Services",
    redirectTo: "/services/",
  },
  {
    linkTitle: "Solutions",
    redirectTo: "/solutions/",
  },
  {
    linkTitle: "Blog",
    redirectTo: "/blog/",
  },
  {
    linkTitle: "Contact us",
    redirectTo: "/contact-us/",
  },
];
