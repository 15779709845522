import React, { FC, ReactNode } from "react";

interface ParagraphWideProps {
  paragraph: ReactNode;
}

export const ParagraphWide: FC<ParagraphWideProps> = ({ paragraph }) => {
  return (
    <div className="col-12 m-0 pt-2 pb-2">
      <p className="grid-paragraph text-dark">{paragraph}</p>
    </div>
  );
};
