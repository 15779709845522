import { goTo } from "@/utils/redirect";
import React from "react";

export default function SocialMediaLinks(): JSX.Element {
  return (
    <div className="rd-navbar-element rd-navbar-element_centered">
      <div className="group-xs">
        <i
          className="icon icon-sm link-social-2 mdi mdi-facebook"
          onClick={() => goTo("https://www.facebook.com/ZartekQ/")}
        >
          {" "}
        </i>
        <i
          className="icon icon-sm link-social-2 mdi mdi-twitter"
          onClick={() => goTo("https://twitter.com/zartekq")}
        />

        <i
          className="icon icon-sm link-social-2 mdi mdi-instagram"
          onClick={() => goTo("https://www.instagram.com/zartek.q")}
        />
      </div>
    </div>
  );
}
