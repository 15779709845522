import { LazyImage } from "@/components/Helpers/LazyImage";
import { Link } from "gatsby";
import React from "react";

export default function BrandLogo(): JSX.Element {
  return (
    <div className="rd-navbar-brand">
      <Link className="brand" to="/">
        <LazyImage
          height={96}
          width={96}
          src="/images/zartek-light-logo.png"
          alt="brand"
          view="0.0"
        />
      </Link>
    </div>
  );
}
