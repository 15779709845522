import React from "react";

export default function Loader({ show }: { show: boolean }): JSX.Element {
  if (!show) {
    return null;
  }

  return (
    <div className="preloader" id="loading">
      <div className="preloader-body">
        <div id="loading-center-object">
          <div className="object" id="object_four" />
          <div className="object" id="object_three" />
          <div className="object" id="object_two" />
          <div className="object" id="object_one" />
        </div>
      </div>
    </div>
  );
}
